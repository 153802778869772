$(document).ready(function () {

    $("body").removeClass("no-js");

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 100) {
            $(".nav-bg").addClass("navigation-scroll");
        } else {
            $(".nav-bg").removeClass("navigation-scroll");
        }
    });

    $(window).trigger('scroll');

    /*  lightweight jQuery plugin to lazy load images */
    $('img.unveil').unveil();

    /* Navigation */
    var navToggle = ['<div class="navMenu"><a class="phone" href="tel:XXX"><i class="fas fa-phone"></i></a><a class="mail" href="mailto:XXX"><i class="fas fa-envelope"></i></a><div class="menuIcons"><div id="toggle-nav"><i class="fas fa-bars"></i></div> <div id="close-nav"><i class="fas fa-times"></i></div></div></div>'].join("");
    $(".nav-bg").append(navToggle);
});

$(function () {
    var pull = $('#toggle-nav');
    menu = $('nav');
    menuHeight = menu.height();

    $('#toggle-nav').on('click', function (e) {
        e.preventDefault();
        topPos = $(window).scrollTop();
        topPosNeg = topPos * -1;
        $(this).addClass("slide-away");
        $('.navi-main').addClass("active-menu");
        $('#close-nav').addClass("slide-in");
        $('html').addClass("fixed-body").css('top', topPosNeg).attr('data-lastTop',topPos);
    });

    $('#close-nav').on('click', function (e) {
        e.preventDefault();
        $(this).removeClass("slide-in");
        $('#toggle-nav').removeClass("slide-away");
        $('.navi-main').removeClass("active-menu");
        $('html').removeClass("fixed-body");
    });

    $(window).resize(function () {
        var w = $(window).width();
        if (w > 320 && menu.is(':hidden')) {
            menu.removeAttr('style');
        }
    });

    $("#page").click(function () {
        $("html").removeClass("fixed-body");
    });

    $("#page").click(function (e) {
        if (!$(e.target).hasClass("fa-bars")) {
            $(".navi-main").removeClass("active-menu");
            $("#close-nav").removeClass("slide-in");
            $("#toggle-nav").removeClass("slide-away");
        }
    });

    // media query event handler
    if (matchMedia) {
        const mq = window.matchMedia("(max-width: 768px)");
        mq.addListener(WidthChange);
        WidthChange(mq);
    }

    // media query change
    function WidthChange(mq) {
        if (mq.matches) {
            if ($('li.hasSub').hasClass("active")) {
                $(".hasSub.active ul.sub").css("display", "block");
            } else {
                $(".hasSub ul.sub").css("display", "none");
            }

            $("#menu > li.hasSub > a").click(function (event) {

                $('ul.sub').not($(this).siblings()).slideUp();
                $(this).siblings("ul.sub").slideToggle();
                $('.hasSub').toggleClass('active');
                event.preventDefault();
            });
        } else {

        }
    };
    /* End */

    /* Back to Top Button */
    var toggleHeight = $(window).outerHeight() * .4;
    $(window).scroll(function () {
        if ($(window).scrollTop() > toggleHeight) {

            $(".m-backtotop").addClass("active");

        } else {

            $(".m-backtotop").removeClass("active");

        }
    });

    $(".m-backtotop").click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, "slow");
        return false;
    });
    /* End */


    /* Addclass to HTML Tag */
    var browser_name = '';
    isIE = /*@cc_on!@*/false || !!document.documentMode;
    isEdge = !isIE && !!window.StyleMedia;
    if (navigator.userAgent.indexOf("Chrome") != -1 && !isEdge) {
        browser_name = 'chrome';
    }
    else if (navigator.userAgent.indexOf("Safari") != -1 && !isEdge) {
        browser_name = 'safari';
    }
    else if (navigator.userAgent.indexOf("Firefox") != -1) {
        browser_name = 'firefox';
    }
    else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
    {
        browser_name = 'ie';
    }
    else if (isEdge) {
        browser_name = 'edge';
    }
    else {
        browser_name = 'other-browser';
    }
    $('html').addClass(browser_name);
    /* End */


    /* Add class to div when in viewport */
    $(window).scroll(function () {
        inViewport();
    });

    $(window).resize(function () {
        inViewport();
    });

    function inViewport() {
        $('.your-div').each(function () {
            var divPos = $(this).offset().top,
                topOfWindow = $(window).scrollTop();

            if (divPos < topOfWindow + 400) {
                $(this).addClass('hello');
            }
        });
    }
    /* End */

});

$(document).on('click', '[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});
